.title {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  color: #fff;
  border-bottom: 1px solid #fff;
  width: 35em;
}

.title-text {
  margin: 0;
}

.game-area {
  display: grid;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
  grid-template-columns: repeat(5, 3em);
  grid-template-rows: repeat(6, 3em);
  gap: 0.25em;
  margin-bottom: 1em;
}

@media only screen and (max-width: 600px) {
  .game-area {
    grid-template-columns: repeat(5, 6em);
    grid-template-rows: repeat(6, 6em);
  }
}

.guess-cell {
  font-size: 2em;
  color: white;
  border: 0.05em solid hsl(240, 2%, 23%);
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: transform 250ms linear;
}

.guess-cell.grey,
.key.grey {
  background-color: rgb(71, 71, 68);
  color: #fff;
}

.guess-cell.yellow,
.key.yellow {
  background-color: rgb(184, 194, 40);
  color: #fff;
}

.guess-cell.green,
.key.green {
  background-color: rgb(11, 141, 22);
  color: #fff;
}

.guess-cell.flip {
  animation: 0.5s linear flipping;
}

.message {
  margin-top: 1em;
  color: #fff;
}

@keyframes flipping {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@keyframes shake {
  10% {
    transform: translateX(-5%);
  }

  30% {
    transform: translateX(5%);
  }

  50% {
    transform: translateX(-7.5%);
  }

  70% {
    transform: translateX(7.5%);
  }

  90% {
    transform: translateX(-5%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes dance {
  20% {
    transform: translateY(-50%);
  }

  40% {
    transform: translateY(5%);
  }

  60% {
    transform: translateY(-25%);
  }

  80% {
    transform: translateY(2.5%);
  }

  90% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(0);
  }
}

.keyboard {
  display: grid;
  grid-template-columns: repeat(20, minmax(auto, 1.25em));
  grid-auto-rows: 3em;
  gap: 0.25em;
  justify-content: center;
}

.special-keys {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
}

.key {
  font-size: inherit;
  grid-column: span 2;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(
    var(--hue, 200),
    var(--saturation, 1%),
    calc(var(--lightness-offset, 0%) + var(--lightness, 51%))
  );
  color: white;
  fill: white;
  text-transform: uppercase;
  border-radius: 0.25em;
  cursor: pointer;
  user-select: none;
}

.key.large {
  grid-column: span 3;
}

.key > svg {
  width: 1.75em;
  height: 1.75em;
}

.key:hover,
.key:focus {
  --lightness-offset: 10%;
}

.alert-container {
  position: fixed;
  top: 10vh;
  left: 50vw;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alert {
  pointer-events: none;
  background-color: hsl(204, 7%, 85%);
  padding: 0.75em;
  border-radius: 0.25em;
  opacity: 1;
  transition: opacity 500ms ease-in-out;
  margin-bottom: 0.5em;
}

.alert:last-child {
  margin-bottom: 0;
}

.alert.hide {
  opacity: 0;
}
