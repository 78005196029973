*,
*::after,
*::before {
  font-family: Arial;
  box-sizing: border-box;
}

body {
  background-color: hsl(240, 3%, 7%);
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-size: clamp(0.5rem, 2.5vmin, 1.5rem);
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin-bottom: 1em;
  padding: 1em;
}
